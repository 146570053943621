.container {
    max-width: 120rem;
    margin: auto;

    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
}

.section-separate {
    margin-bottom: 7.2rem;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-mt-small {
    margin-top: 2.4rem;
}

.text-right {
    text-align: right !important;
}

.py-small {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.w-100 {
    width: 100% !important;
}

.text-tertiary {
    color: $color-tertiary !important;
}

.text-primary {
    color: $color-primary !important;
}

.color-transparent {
    color: transparent !important;
}

.text-center {
    text-align: center !important;
}

.flex-lrdesktop {
    @media (max-width: 1050px) {
        display: flex !important;
    }
}

.hide-lrdesktop {
    @media (max-width: 1050px) {
        display: none !important;
    }
}
