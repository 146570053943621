.books-section {
    &__h-main {
        margin-bottom: 2.4rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.4rem;
    }
}

.books {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.6rem;

    &--grid4 {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
    }

    // @media (max-width: 360px) {
    //     grid-template-columns: 1fr;
    // }
}

.book {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;

    &__header {
        position: relative;
        height: 27rem;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 99;
            opacity: 0;
            transition: all 0.2s;
        }

        &--nohover::after {
            display: none !important;
        }

        // @media (max-width: 360px) {
        //     height: 35rem;
        // }
    }

    &:hover &__header::after {
        opacity: 1;
    }

    &__actions {
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        z-index: 999;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;

        opacity: 0;
        transition: all 0.3s;
    }

    &:hover &__actions {
        opacity: 1;
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50%;
        width: 3.2rem;
        height: 3.2rem;
        border: none;
        color: #555;
        cursor: pointer;
        pointer-events: none;

        & ion-icon {
            pointer-events: none;
        }
    }

    &:hover &__action {
        pointer-events: all;
    }

    &__action-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        flex-shrink: 0;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__main {
        padding: 1.2rem 1.6rem;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6rem;
    }

    &__name {
        color: $color-secondary;
        font-weight: 600;
        font-size: 1.6rem;
        align-self: stretch;
    }

    &__rating {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    &__rating-stars {
        display: flex;
        align-items: center;
        gap: 0.3rem;
    }

    &__rating-star {
        color: #777;

        &--active {
            color: $color-primary;
        }
    }

    &__price {
        display: flex;
        align-items: center;
        margin-top: 1.6rem;
        gap: 1.8rem;

        font-size: 1.8rem;
        color: #087f5b;
        font-weight: 500;

        & small {
            font-size: 1rem;
            color: #aaa;
            text-decoration: line-through;
        }
    }
}
