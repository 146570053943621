.categories-section {
    &__header {
        margin-bottom: 2.4rem;
    }
}

.categories {
    display: flex;
    gap: 2.4rem;

    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.category {
    flex-shrink: 0;
    min-width: 25rem;

    padding: 3.6rem 3.6rem;
    cursor: pointer;
    border-radius: 1.2rem;

    background-color: #fff;

    // &--1 {
    //     background-color: #f3f0ff;
    // }

    // &--2 {
    //     background-color: #fff9db;
    // }

    // &--3 {
    //     background-color: #fff4e6;
    // }

    // &--4 {
    //     background-color: #e7f5ff;
    // }

    // &--5 {
    //     background-color: #fff0f6;
    // }

    &__icon-block {
        margin-bottom: 1.6rem;

        & svg {
            display: block;
            width: 3.6rem;
            height: 3.6rem;
        }
    }

    // &--1 &__icon-block svg {
    //     color: #7048e8;
    // }

    // &--2 &__icon-block svg {
    //     color: #f59f00;
    // }

    // &--3 &__icon-block svg {
    //     color: #f76707;
    // }

    // &--4 &__icon-block svg {
    //     color: #1c7ed6;
    // }

    // &--5 &__icon-block svg {
    //     color: #d6336c;
    // }

    &__name {
        margin-bottom: 0.6rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: $color-secondary;
    }

    &__link {
        text-decoration: none;
        color: $color-tertiary;

        display: flex;
        align-items: center;
        gap: 0.6rem;
    }
}
