@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cantata+One&display=swap");
@keyframes pageLoaderAnim {
    0% {
        left: -110%;
    }
    100% {
        left: 110%;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes barWidth {
    0% {
        width: 0%;
    }
    25% {
        width: 50%;
    }
    50% {
        width: 100%;
    }
    75% {
        width: 50%;
    }
    100% {
        width: 0%;
    }
}

@keyframes barWidth2 {
    0% {
        width: 0%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

@keyframes notificationSlideIn {
    0% {
        transform: translateX(40rem);
    }
    100% {
        transform: translateX(0);
    }
}

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
}
@media (max-width: 550px) {
    html {
        font-size: 55%;
    }
}
@media (max-width: 360px) {
    html {
        font-size: 50%;
    }
}

body {
    box-sizing: border-box;
    background-color: #f7f7f7;
    overflow-x: hidden;
}

.main {
    min-height: calc(100vh - 244px);
}

body {
    color: #555;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.heading-primary {
    font-size: 3.6rem;
    font-family: "Cantata One", serif;
    color: #12124e;
}

.container {
    max-width: 120rem;
    margin: auto;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
}

.section-separate {
    margin-bottom: 7.2rem;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-mt-small {
    margin-top: 2.4rem;
}

.text-right {
    text-align: right !important;
}

.py-small {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.w-100 {
    width: 100% !important;
}

.text-tertiary {
    color: #40c057 !important;
}

.text-primary {
    color: #ff922b !important;
}

.color-transparent {
    color: transparent !important;
}

.text-center {
    text-align: center !important;
}

@media (max-width: 1050px) {
    .flex-lrdesktop {
        display: flex !important;
    }
}

@media (max-width: 1050px) {
    .hide-lrdesktop {
        display: none !important;
    }
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    height: 5.2rem;
    background-color: #40c057;
    color: #fff;
    border: none;
    font-family: inherit;
    font-size: 1.6rem;
    border-radius: 0.6rem;
    text-decoration: none;
    cursor: pointer;
}
.button--sm {
    height: 4.8rem;
}
.button ion-icon {
    display: block;
}

.button-secondary {
    padding: 1.2rem 1.8rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: inherit;
    border: 2px solid #12124e;
    color: #12124e;
    cursor: pointer;
    transition: all 0.3s;
    background-color: transparent;
    text-decoration: none;
}
.button-secondary--active,
.button-secondary:hover {
    background-color: #12124e;
    color: #fff;
}

.button-primary {
    padding: 1.2rem 1.8rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: inherit;
    background-color: #40c057;
    color: #fff;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.alert {
    background-color: #f1f1f1;
    border-radius: 6px;
    display: flex;
    overflow: hidden;
    position: relative;
}
.alert__icon-box {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 4.8rem;
}
.alert__icon-box ion-icon {
    color: #fff;
    display: block;
    height: 2.4rem;
    width: 2.4rem;
}
.alert--error .alert__icon-box {
    background-color: #ff6b6b;
}
.alert--warning .alert__icon-box {
    background-color: #fcc419;
}
.alert--success .alert__icon-box {
    background-color: #51cf66;
}
.alert__message {
    flex: 1 1;
    font-size: 1.4rem;
    padding: 1.2rem 2.4rem;
}
.alert__close-block {
    padding-right: 1.2rem;
    padding-top: 0.8rem;
}
.alert__close {
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
}
.alert__close ion-icon {
    color: #4f4f4f;
    display: block;
    height: 1.4rem;
    width: 1.4rem;
}

.copyright {
    border-top: 1px solid #ddd;
    padding: 1.2rem 0;
}
.copyright__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
}
@media (max-width: 400px) {
    .copyright__main {
        flex-direction: column;
    }
}
.copyright__text {
    color: #12124e;
    font-size: 1.6rem;
}
.copyright__text a:link,
.copyright__text a:visited {
    text-decoration: none;
    color: #2f9e44;
}
@media (max-width: 400px) {
    .copyright__text {
        text-align: center;
    }
}
.copyright__img {
    height: 3.6rem;
}

.search-input {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    height: 4.8rem;
    background-color: #fff;
    padding: 0 1.6rem;
    border-radius: 0.6rem;
}
.search-input ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
}
.search-input input {
    min-width: 0;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
}

.check-block {
    padding: 1.2rem 1.6rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    background-color: #fff;
    cursor: pointer;
}
.check-block--active {
    background-color: #eaeaea;
}
.check-block > *:last-child {
    margin-left: auto;
}
.check-block input {
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-block input:checked {
    border: 1px solid #2f9e44;
}
.check-block input:checked::after {
    content: "";
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #2f9e44;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form-label {
    font-weight: 500;
}

.form-input {
    height: 4.8rem;
    font-family: inherit;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    padding: 0 1.6rem;
    font-size: inherit;
}

.form-input-group {
    position: relative;
    display: flex;
    flex-direction: column;
}
.form-input-group input {
    padding-right: 6.4rem;
}
.form-input-group button {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.8rem;
    height: 100%;
    z-index: 9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
}
.form-input-group button ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
}

.h-top {
    border-bottom: 1px solid #ddd;
    /*Link*/
}
.h-top__main {
    padding: 1.2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
}
.h-top__phone {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    color: #12124e;
}
.h-top__phone-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: #ff922b;
}
.h-top__socials {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.h-top__social-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: #12124e;
}

.h-main__main {
    padding: 1.8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
}

.h-main__brand {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    cursor: pointer;
}

.h-main__brand-img {
    display: block;
    height: 4.8rem;
}

.h-main__brand-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.2rem;
    background-color: #ff922b;
    border-radius: 0.6rem;
}
.h-main__brand-logo ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: #fff;
}

.h-main__brand-name {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #12124e;
}

.h-main__navbar {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2.4rem;
}
@media (max-width: 900px) {
    .h-main__navbar {
        display: none;
    }
}

.h-main__navbar-link:link,
.h-main__navbar-link:visited {
    text-decoration: none;
    color: #12124e;
    position: relative;
}

.h-main__navbar-link label {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    font-size: 0.8rem;
    text-transform: uppercase;
    display: inline-block;
    color: #fff;
    background-color: #40c057;
    padding: 0.1rem 0.3rem;
}

.h-main__navbar-link--active {
    font-weight: 600;
}

.h-main__right {
    display: flex;
    align-items: center;
    gap: 2.4rem;
}

.h-main__icon-link:link {
    text-decoration: none;
    position: relative;
}

.h-main__icon-link ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: #12124e;
}

.h-main__icon-link label {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.6rem;
    color: #fff;
    font-size: 0.8rem;
    background-color: #40c057;
}

.h-bottom {
    background-color: #40c057;
}
.h-bottom__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.h-bottom__categories-block {
    height: 5.6rem;
}
.h-bottom__categories-h-main {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    color: #fff;
    padding: 0 2.4rem;
    background-color: #2f9e44;
    font-family: inherit;
    border: none;
    cursor: pointer;
}
.h-bottom__categories-h-main--1 {
    padding: 0;
    background-color: transparent;
    gap: 1.2rem !important;
}
.h-bottom__categories-h-main p {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.h-bottom__categories-h-main p ion-icon {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
}
@media (max-width: 400px) {
    .h-bottom__categories-h-main {
        gap: 3.2rem;
    }
}
.h-bottom__categories-body {
    display: none;
    list-style: none;
    flex-direction: column;
    z-index: 999;
    position: relative;
}
.h-bottom__categories-block:hover .h-bottom__categories-body {
    display: flex;
}
.h-bottom__category a:link,
.h-bottom__category a:visited {
    display: block;
    padding: 1.2rem 1.6rem;
    text-decoration: none;
    background-color: #fff;
    color: #12124e;
}
.h-bottom__category a:hover,
.h-bottom__category a:active {
    background-color: #fafafa;
}
.h-bottom__search-form {
    display: flex;
    gap: 2.4rem;
    background-color: #fff;
    height: 3.6rem;
    border-radius: 10rem;
    padding: 0.6rem;
    overflow: hidden;
}
@media (max-width: 900px) {
    .h-bottom__search-form {
        display: none;
    }
}
.h-bottom__search-input {
    border: none;
    font-size: 1.2rem;
    font-family: inherit;
    padding-left: 1.2rem;
    min-width: 0;
    width: 20rem;
    outline: none;
}
.h-bottom__search-cat-select {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 1.2rem;
    cursor: pointer;
}
.h-bottom__search-cat-icons {
    display: flex;
    flex-direction: column;
}
.h-bottom__search-cat-icons ion-icon {
    display: block;
}
.h-bottom__search-btn {
    width: 2.4rem;
    height: 2.4rem;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #40c057;
}
.h-bottom__search-icon {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    color: #fff;
}
.h-bottom__right {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    color: #fff;
}
.h-bottom__right-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
}

.newsletter__container {
    background-color: #2f9e44;
    border-radius: 1.6rem;
    padding: 5.2rem 7.2rem !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4.8rem;
}
@media (max-width: 750px) {
    .newsletter__container {
        padding: 5.2rem 2.4rem !important;
        justify-content: center;
        text-align: center;
    }
}

.newsletter__img {
    height: 20rem;
}
@media (max-width: 768px) {
    .newsletter__img {
        display: none;
    }
}

.newsletter__heading {
    font-size: 5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 2.4rem;
}
@media (max-width: 850px) {
    .newsletter__heading {
        font-size: 4rem;
    }
}
@media (max-width: 600px) {
    .newsletter__heading {
        font-size: 3.2rem;
    }
}

.newsletter__sub-heading {
    font-size: 1.75rem;
    margin-bottom: 3.6rem;
}

.newsletter__search-form {
    height: 5.6rem;
    background-color: #fff;
    border-radius: 10rem;
    overflow: hidden;
    padding: 0.6rem;
    display: flex;
    gap: 1.2rem;
}
@media (max-width: 600px) {
    .newsletter__search-form {
        flex-direction: column;
        height: auto;
        border-radius: 1.2rem;
        gap: 0.6rem;
    }
}

.newsletter__search-input {
    min-width: 0;
    flex: 1;
    background-color: transparent;
    font-family: inherit;
    padding-left: 2.4rem;
    border: none;
    outline: none;
}
@media (max-width: 600px) {
    .newsletter__search-input {
        flex: 0 0 4.8rem;
        padding-left: 1.2rem;
    }
}

.newsletter__button {
    background-color: #40c057;
    padding: 0 2.4rem;
    border: none;
    border-radius: 10rem;
    font-family: inherit;
    color: #fff;
}
@media (max-width: 600px) {
    .newsletter__button {
        height: 4.8rem;
        border-radius: 1.2rem;
    }
}

.pgn {
    position: fixed;
    right: 1.2rem;
    bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 35rem;
    height: auto;
}
.pgn__notification {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1.6rem;
    border-radius: 3px;
    box-shadow: 0 1.4rem 4rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: relative;
    z-index: 999;
    animation: notificationSlideIn 0.5s ease-in-out 0.3s;
    animation-fill-mode: both;
}
.pgn__notification ion-icon {
    width: 2.4rem;
    height: 2.4rem;
}
.pgn__notification--success {
    border-left: 6px solid #37b24d;
}
.pgn__notification--success ion-icon {
    color: #37b24d;
}
.pgn__notification--error {
    border-left: 6px solid #f03e3e;
}
.pgn__notification--error ion-icon {
    color: #f03e3e;
}
.pgn__notification--info {
    border-left: 6px solid #1c7ed6;
}
.pgn__notification--info ion-icon {
    color: #1c7ed6;
}
.pgn__notification--warning {
    border-left: 6px solid #fcc419;
}
.pgn__notification--warning ion-icon {
    color: #fcc419;
}
.pgn__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}
.pgn__heading {
    font-size: 1.8rem;
    font-weight: 600;
}
.pgn__text {
    font-size: 1.4rem;
}
.pgn__close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
}
.pgn__close ion-icon {
    color: #4f4f4f;
    display: block;
    height: 1.2rem;
    width: 1.2rem;
}

.pagination {
    display: flex;
    height: 4.8rem;
    width: 9.6rem;
    border-radius: 10rem;
    overflow: hidden;
    background-color: #40c057;
}
.pagination__control {
    flex: 1;
    border: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2;
}
.pagination__control--hovered,
.pagination__control:hover {
    background-color: #2f9e44;
}
.pagination__control ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #fff;
}

.pagination2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;
}
.pagination2 div {
    display: flex;
    gap: 1.2rem;
}
.pagination2 button {
    background-color: #eaeaea;
    border: none;
    width: 3.6rem;
    height: 3.6rem;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination2 ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #888;
}

.placeholder {
    background: darkgray;
    background: linear-gradient(
        to right,
        #eeeeee 10%,
        #dddddd 18%,
        #eeeeee 33%
    );
    background-size: 800px 104px;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
}

/**
 * ==============================================
 * Preloader
 * ==============================================
 */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
}
.preloader__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    width: 35rem;
    max-width: 100%;
}
.preloader__logo {
    display: block;
    height: 4rem;
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2f9e44;
    color: #2f9e44;
    box-shadow: 9999px 0 0 0 #2f9e44;
    animation: dot-falling 1s infinite linear;
    animation-delay: 0.1s;
}

.dot-falling::before,
.dot-falling::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-falling::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2f9e44;
    color: #2f9e44;
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
}

.dot-falling::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2f9e44;
    color: #2f9e44;
    animation: dot-falling-after 1s infinite linear;
    animation-delay: 0.2s;
}

@keyframes dot-falling {
    0% {
        box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 9999px 0 0 0 #2f9e44;
    }
    100% {
        box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
}

@keyframes dot-falling-before {
    0% {
        box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 9984px 0 0 0 #2f9e44;
    }
    100% {
        box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
}

@keyframes dot-falling-after {
    0% {
        box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 10014px 0 0 0 #2f9e44;
    }
    100% {
        box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
}

.select-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
}
.select-box__main {
    background-color: #fff;
    height: 45rem;
    width: 35rem;
    max-width: 100%;
    border-radius: 1.2rem;
    overflow: hidden;
    padding: 1.2rem 0 2.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}
.select-box__header {
    text-align: center;
    margin-bottom: 2rem;
}
.select-box__header div {
    display: inline-block;
    width: 40%;
    border-radius: 10rem;
    height: 4px;
    background-color: rgba(28, 127, 214, 0.1);
}
.select-box__heading {
    font-size: 1.8rem;
    font-weight: 500;
    padding-left: 1.6rem;
    margin-bottom: 1.6rem;
}
.select-box__search {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    height: 3.6rem;
    border: 1px solid #eaeaea;
    margin: 0 1.6rem;
    padding: 0 1.2rem;
    margin-bottom: 1.8rem;
}
.select-box__search input {
    border: none;
    outline: none;
    background-color: transparent;
    min-width: 0;
    flex: 1;
    font-family: inherit;
    background-color: transparent;
}
.select-box__search ion-icon {
    display: block;
}
.select-box__options {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: 0 1.6rem;
}
.select-box__options::-webkit-scrollbar {
    display: none;
}
.select-box__option {
    padding: 1.6rem 1.8rem;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
}
.select-box__option:hover {
    background-color: #f7f7f7;
}
.select-box__radio {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid #aaa;
    border-radius: 50%;
    cursor: pointer;
}
.select-box__radio::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #2f9e44;
    border-radius: 50%;
    display: none;
}
.select-box__radio:checked {
    border: 1px solid #2f9e44;
}
.select-box__radio:checked::after {
    display: block;
}

.time {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    background-color: #40c057;
    border-radius: 10rem;
    padding: 0.6rem 1.2rem;
    font-weight: 500;
    color: #fff;
    font-size: 1.2rem;
}
.time ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
}

.auth {
    width: 40rem;
    max-width: 100%;
    margin: auto;
    padding: 5.6rem 0;
}
.auth__logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.6rem;
}
.auth__logo {
    display: block;
    height: 3.8rem;
}
.auth__header {
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: center;
}
.auth__heading {
    font-size: 2.8rem;
    font-weight: 700;
}
.auth__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}
.auth__form-footer {
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}
.auth__footer {
    margin-top: 4.2rem;
}
@media (max-width: 1150px) {
    .auth__footer {
        text-align: center;
    }
}
.auth__footer a:link,
.auth__footer a:visited {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
}

.books-section__h-main {
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
}

.books {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.6rem;
}
.books--grid4 {
    grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 900px) {
    .books {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (max-width: 700px) {
    .books {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 550px) {
    .books {
        grid-template-columns: repeat(2, 1fr);
    }
}

.book {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
}
.book__header {
    position: relative;
    height: 27rem;
}
.book__header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    opacity: 0;
    transition: all 0.2s;
}
.book__header--nohover::after {
    display: none !important;
}
.book:hover .book__header::after {
    opacity: 1;
}
.book__actions {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    opacity: 0;
    transition: all 0.3s;
}
.book:hover .book__actions {
    opacity: 1;
}
.book__action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    border: none;
    color: #555;
    cursor: pointer;
    pointer-events: none;
}
.book__action ion-icon {
    pointer-events: none;
}
.book:hover .book__action {
    pointer-events: all;
}
.book__action-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
}
.book__img {
    display: block;
    width: 100%;
    height: 100%;
}
.book__main {
    padding: 1.2rem 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}
.book__name {
    color: #12124e;
    font-weight: 600;
    font-size: 1.6rem;
    align-self: stretch;
}
.book__rating {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.book__rating-stars {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
.book__rating-star {
    color: #777;
}
.book__rating-star--active {
    color: #ff922b;
}
.book__price {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
    gap: 1.8rem;
    font-size: 1.8rem;
    color: #087f5b;
    font-weight: 500;
}
.book__price small {
    font-size: 1rem;
    color: #aaa;
    text-decoration: line-through;
}

.categories-section__header {
    margin-bottom: 2.4rem;
}

.categories {
    display: flex;
    gap: 2.4rem;
    overflow-x: scroll;
}
.categories::-webkit-scrollbar {
    display: none;
}

.category {
    flex-shrink: 0;
    min-width: 25rem;
    padding: 3.6rem 3.6rem;
    cursor: pointer;
    border-radius: 1.2rem;
    background-color: #fff;
}
.category__icon-block {
    margin-bottom: 1.6rem;
}
.category__icon-block svg {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
}
.category__name {
    margin-bottom: 0.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #12124e;
}
.category__link {
    text-decoration: none;
    color: #40c057;
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.hero {
    height: 60vh;
    background-color: #d3f9d8;
}
.hero__container {
    height: 100%;
    display: flex;
    align-items: center;
}
.hero__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.2rem;
}
.hero__label {
    font-size: 1.6rem;
    color: #12124e;
    font-weight: 600;
}
.hero__heading {
    font-size: 4.8rem;
    line-height: 0.8;
}
@media (max-width: 420px) {
    .hero__heading {
        line-height: 1;
    }
}
.hero__btn {
    background-color: #40c057;
    border: none;
    border-radius: 10rem;
    padding: 1.2rem 2.4rem;
    color: #fff;
    font-family: inherit;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1.2rem;
}
.hero__btn ion-icon {
    display: block;
}

.book-details-block {
    padding: 6.2rem 0;
}

.book-details {
    display: flex;
    gap: 4.8rem;
    max-width: 85rem;
    margin: auto;
    margin-bottom: 4.8rem;
}
@media (max-width: 850px) {
    .book-details {
        gap: 2.4rem;
    }
}
@media (max-width: 750px) {
    .book-details {
        flex-direction: column;
    }
}
.book-details__img-block {
    flex: 0 0 30rem;
}
@media (max-width: 750px) {
    .book-details__img-block {
        display: block;
        text-align: center;
    }
}
.book-details__img {
    display: block;
    width: 100%;
}
@media (max-width: 750px) {
    .book-details__img {
        display: inline-block;
        width: 30rem;
    }
}
.book-details__main {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
@media (max-width: 750px) {
    .book-details__main {
        text-align: center;
    }
}
.book-details__name {
    font-size: 2.8rem;
    font-weight: 600;
    color: #12124e;
}
.book-details__review {
    display: flex;
    align-items: center;
    gap: 1.8rem;
}
@media (max-width: 750px) {
    .book-details__review {
        justify-content: center;
    }
}
.book-details__stars {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}
.book-details__stars ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
}
.book-details__stars ion-icon.active {
    color: #ff922b;
}
.book-details__description {
    font-size: 1.8rem;
    color: #12124e;
    text-align: justify;
}
@media (max-width: 750px) {
    .book-details__description {
        text-align: center;
    }
}
.book-details__price {
    display: flex;
    align-items: center;
    gap: 1.6rem;
}
@media (max-width: 750px) {
    .book-details__price {
        justify-content: center;
    }
}
.book-details__price-main {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    color: #2f9e44;
    font-size: 2rem;
}
.book-details__price-del {
    font-size: 1.2rem;
    text-decoration: line-through;
}
.book-details__wishlist-btn {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 2.2rem;
    text-transform: uppercase;
    color: #12124e;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-family: inherit;
    font-weight: 500;
}
.book-details__wishlist-btn ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
}
@media (max-width: 750px) {
    .book-details__wishlist-btn {
        justify-content: center;
    }
}
.book-details__main-footer {
    margin-top: auto;
}
.book-details__actions {
    display: flex;
    align-items: center;
    gap: 1.6rem;
}
@media (max-width: 750px) {
    .book-details__actions {
        justify-content: center;
    }
}
@media (max-width: 400px) {
    .book-details__actions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .book-details__actions > *:first-child {
        grid-column: 1 / -1;
    }
}

.book-tab {
    margin-bottom: 4.8rem;
}
.book-tab__header {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ddd;
    border-bottom: 0;
}
.book-tab__header button {
    background-color: transparent;
    color: #12124e;
    font-size: 1.4rem;
    transition: all 0.2s;
    font-family: inherit;
    padding: 1.2rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.book-tab__header button:not(:last-child) {
    border-right: 1px solid #ddd;
}
.book-tab__header button.active,
.book-tab__header button:hover {
    background-color: #ddd;
}
.book-tab__body {
    border: 1px solid #ddd;
    padding: 3.6rem 2.4rem;
}
.book-tab__block:not(:last-child) {
    margin-bottom: 3.6rem;
}
.book-tab__heading {
    font-size: 2.2rem;
    color: #12124e;
    font-weight: 400;
    margin-bottom: 1.8rem;
}
.book-tab__text {
    font-size: 1.6rem;
}
.book-tab__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.book-tab__list li {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-size: 1.6rem;
}

.similar-books {
    display: flex;
    gap: 1.8rem;
}
.similar-books__left {
    flex: 0 0 35rem;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    padding: 2.4rem;
}
.similar-books__left::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}
@media (max-width: 800px) {
    .similar-books__left {
        display: none;
    }
}
.similar-books__left img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: cover;
}
.similar-books__left-heading {
    font-size: 4.8rem;
    color: #2f9e44;
    font-weight: 600;
    margin-bottom: 2rem;
}
.similar-books__left-contact {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: #fff;
}
.similar-books__left-contact p:first-child {
    font-size: 1.8rem;
}
.similar-books__left-contact p:last-child {
    font-size: 2.6rem;
    font-weight: 500;
}
.similar-books__right {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    gap: 1.8rem;
}
.similar-books__right::-webkit-scrollbar {
    display: none;
}
.similar-books__right > * {
    flex: 0 0 25rem;
}

.books-block {
    padding: 6.2rem 0;
}
.books-block__heading {
    text-align: center;
    font-size: 3.6rem;
    font-weight: 500;
    margin-bottom: 2.4rem;
}
.books-block__filter {
    text-align: center;
    margin-bottom: 2.4rem;
    display: none;
}
@media (max-width: 1050px) {
    .books-block__filter {
        display: block;
    }
}
.books-block__filter-btn {
    display: inline-flex;
    align-items: center;
    gap: 1.2rem;
    font-family: inherit;
    color: #fff;
    background-color: #2f9e44;
    border: none;
    padding: 1.2rem 2.4rem;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 1.6rem;
}
.books-block__filter-btn ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: #fff;
}
.books-block__main {
    display: flex;
    gap: 1.8rem;
}
@media (max-width: 1050px) {
    .books-block__main {
        flex-direction: column;
    }
}
.books-block__main-left {
    flex: 0 0 30rem;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
}
@media (max-width: 1050px) {
    .books-block__main-left {
        width: 35rem;
        max-width: 100%;
        margin: auto;
    }
}
@media (max-width: 450px) {
    .books-block__main-left {
        width: 100%;
    }
}
.books-block__main-right {
    flex: 1;
}
.books-block__input-groups {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.books-block-card {
    padding: 1.6rem;
    border: 1px solid #eaeaea;
    margin-bottom: 2.4rem;
    border-radius: 0.6rem;
}
.books-block-card__heading {
    color: #000;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    font-weight: 500;
}

.cart-block {
    padding: 6.2rem 0;
}
.cart-block__heading {
    text-align: center;
    font-size: 3.6rem;
    font-weight: 500;
    margin-bottom: 2.4rem;
}
.cart-block__main {
    display: flex;
    gap: 1.8rem;
}
.cart-block__main-left {
    flex: 1;
}
.cart-block__main-right {
    flex: 0 0 30rem;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
}
@media (max-width: 950px) {
    .cart-block__main-right {
        display: none;
    }
}
.cart-block__sidebar {
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.6rem;
    padding: 2rem;
}

.checkout-block__heading {
    font-weight: 500;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #ddd;
}

.checkout-block__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: 1.8rem;
    padding: 1.2rem 0;
    border-bottom: 1px solid #ddd;
}

.cart-products__header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    margin-bottom: 1.6rem;
}

.cart-products__heading {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-weight: 500;
}
.cart-products__heading ion-icon {
    display: block;
    color: #40c057;
    width: 1.8rem;
    height: 1.8rem;
}

.cart-products__header-right {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.cart-products__header-right span {
    color: #2f9e44;
    font-weight: 500;
}

.cart-product {
    display: flex;
    gap: 2.4rem;
    padding: 1.6rem;
    border-bottom: 1px solid #ddd;
}
@media (max-width: 650px) {
    .cart-product {
        gap: 2.4rem;
    }
}
@media (max-width: 600px) {
    .cart-product {
        display: grid;
        grid-template-columns: auto 1fr;
    }
}
.cart-product__img-block {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.cart-product__img-block ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #40c057;
}
.cart-product__img {
    width: 8rem;
}
.cart-product__right {
    margin-left: auto;
}
@media (max-width: 600px) {
    .cart-product__right {
        margin-left: 0;
        grid-column: 1 / -1;
    }
}
.cart-product__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.cart-product__author {
    font-size: 1.4rem;
    margin-bottom: 4px;
}
.cart-product__name {
    font-size: 2.2rem;
    color: #12124e;
    margin-bottom: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}
@media (max-width: 450px) {
    .cart-product__name {
        font-size: 1.8rem;
    }
}
.cart-product__price-block {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    color: #2f9e44;
}
.cart-product__label {
    display: inline-block;
    padding: 0.8rem;
    font-size: 1rem;
    color: #40c057;
    border: 1px solid #40c057;
    border-radius: 0.6rem;
}
@media (max-width: 600px) {
    .cart-product__label {
        border: none;
        padding: 0;
    }
}
@media (max-width: 450px) {
    .cart-product__label {
        display: none;
    }
}
.cart-product__link:link,
.cart-product__link:visited {
    color: #12124e;
    text-decoration: underline;
    font-size: 1.2rem;
    margin-top: auto;
}
.cart-product__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.cart-product__btns {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.cart-product__btns button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    cursor: pointer;
    border-radius: 0.6rem;
    border: none;
    background-color: #fff;
}
.cart-product__btns button ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #555;
}
.cart-product__right-text {
    margin-top: auto;
    font-size: 1.2rem;
}

.not-found {
    padding: 6.2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.not-found__404 {
    font-size: 25rem;
    font-weight: 500;
    margin-bottom: -4.8rem;
}
@media (max-width: 700px) {
    .not-found__404 {
        font-size: 20rem;
    }
}
@media (max-width: 350px) {
    .not-found__404 {
        font-size: 15rem;
    }
}
.not-found__heading {
    font-size: 4.8rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
}
@media (max-width: 700px) {
    .not-found__heading {
        font-size: 2.4rem;
    }
}
.not-found__description {
    font-size: 1.8rem;
    margin-bottom: 1.8rem;
}
@media (max-width: 700px) {
    .not-found__description {
        font-size: 1.4rem;
    }
}
.not-found__button {
    display: block;
    padding: 1.2rem 2.4rem;
    border-radius: 0.6rem;
    border: none;
    color: #fff;
    background-color: #2f9e44;
    font-family: inherit;
    cursor: pointer;
}

.wishlist {
    padding: 6.2rem 0;
}
.wishlist__heading {
    text-align: center;
    font-size: 3.6rem;
    font-weight: 500;
    margin-bottom: 2.4rem;
}
.wishlist__main {
    max-width: 80rem;
    margin: auto;
}

