.not-found {
    padding: 6.2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__404 {
        font-size: 25rem;
        font-weight: 500;
        margin-bottom: -4.8rem;

        @media (max-width: 700px) {
            font-size: 20rem;
        }

        @media (max-width: 350px) {
            font-size: 15rem;
        }
    }

    &__heading {
        font-size: 4.8rem;
        font-weight: 700;
        margin-bottom: 1.2rem;

        @media (max-width: 700px) {
            font-size: 2.4rem;
        }
    }

    &__description {
        font-size: 1.8rem;
        margin-bottom: 1.8rem;

        @media (max-width: 700px) {
            font-size: 1.4rem;
        }
    }

    &__button {
        display: block;
        padding: 1.2rem 2.4rem;
        border-radius: 0.6rem;
        border: none;
        color: #fff;
        background-color: $color-tertiary-darker;
        font-family: inherit;
        cursor: pointer;
    }
}
