.search-input {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    height: 4.8rem;
    background-color: #fff;
    padding: 0 1.6rem;
    border-radius: 0.6rem;

    & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
    }

    & input {
        min-width: 0;
        flex: 1;
        border: none;
        outline: none;
        background-color: transparent;
        font-family: inherit;
        font-size: inherit;
    }
}

.check-block {
    padding: 1.2rem 1.6rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    background-color: #fff;
    cursor: pointer;

    &--active {
        background-color: #eaeaea;
    }

    & > *:last-child {
        margin-left: auto;
    }

    & input {
        appearance: none;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        border: 1px solid #000;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        &:checked {
            border: 1px solid $color-tertiary-darker;

            &::after {
                content: "";
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                background-color: $color-tertiary-darker;
            }
        }
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form-label {
    font-weight: 500;
}

.form-input {
    height: 4.8rem;
    font-family: inherit;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    padding: 0 1.6rem;
    font-size: inherit;
}

.form-input-group {
    position: relative;

    display: flex;
    flex-direction: column;

    & input {
        padding-right: 6.4rem;
    }

    & button {
        position: absolute;
        top: 0;
        right: 0;
        width: 4.8rem;
        height: 100%;
        z-index: 9;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        background-color: transparent;

        & ion-icon {
            display: block;
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}
