.hero {
    height: 60vh;
    // background-color: #ffe3e3;
    background-color: #d3f9d8;

    &__container {
        height: 100%;

        display: flex;
        align-items: center;
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.2rem;
    }

    &__label {
        font-size: 1.6rem;
        color: $color-secondary;
        font-weight: 600;
    }

    &__heading {
        font-size: 4.8rem;
        line-height: 0.8;

        @media (max-width: 420px) {
            line-height: 1;
        }
    }

    &__btn {
        background-color: $color-tertiary;
        border: none;
        border-radius: 10rem;
        padding: 1.2rem 2.4rem;
        color: #fff;
        font-family: inherit;
        font-size: 1.2rem;

        display: flex;
        align-items: center;
        gap: 1.2rem;

        margin-top: 1.2rem;

        & ion-icon {
            display: block;
        }
    }
}
