.h-top {
    border-bottom: 1px solid #ddd;

    &__main {
        padding: 1.2rem 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.4rem;
    }

    &__phone {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        color: $color-secondary;
    }

    &__phone-icon {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        color: $color-primary;
    }

    &__socials {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    /*Link*/
    &__social {
    }

    &__social-icon {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        color: $color-secondary;
    }
}

.h-main {
    // position: sticky;
    // top: 0;
    // left: 0;
    // width: 100%;
    // background-color: #f7f7f7;
    // z-index: 999;

    &__main {
        padding: 1.8rem 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.4rem;
    }

    &__brand {
        display: flex;
        align-items: center;
        gap: 1.8rem;

        cursor: pointer;
    }

    &__brand-img {
        display: block;
        height: 4.8rem;
    }

    &__brand-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4.8rem;
        height: 4.2rem;
        background-color: $color-primary;
        border-radius: 0.6rem;

        & ion-icon {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            color: #fff;
        }
    }

    &__brand-name {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $color-secondary;
    }

    &__navbar {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 2.4rem;

        @media (max-width: 900px) {
            display: none;
        }
    }

    &__navbar-link {
        &:link,
        &:visited {
            text-decoration: none;
            color: $color-secondary;
            position: relative;
        }

        & label {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-100%);

            font-size: 0.8rem;
            text-transform: uppercase;
            display: inline-block;
            color: #fff;
            background-color: #40c057;
            padding: 0.1rem 0.3rem;
        }

        &--active {
            // color: $color-primary !important;
            font-weight: 600;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 2.4rem;
    }

    &__icon-link {
        &:link {
            text-decoration: none;
            position: relative;
        }

        & ion-icon {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            color: $color-secondary;
        }

        & label {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(25%, -25%);

            display: inline-flex;
            align-items: center;
            justify-content: center;

            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.6rem;

            color: #fff;
            font-size: 0.8rem;
            background-color: $color-tertiary;
        }
    }
}

.h-bottom {
    background-color: $color-tertiary;

    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__categories-block {
        height: 5.6rem;
    }

    &__categories-h-main {
        height: 100%;

        display: flex;
        align-items: center;
        gap: 2.4rem;

        color: #fff;
        padding: 0 2.4rem;
        background-color: $color-tertiary-darker;
        font-family: inherit;
        border: none;
        cursor: pointer;

        &--1 {
            padding: 0;
            background-color: transparent;
            gap: 1.2rem !important;
        }

        & p {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            & ion-icon {
                display: block;
                width: 2.8rem;
                height: 2.8rem;
            }
        }

        @media (max-width: 400px) {
            gap: 3.2rem;
        }
    }

    &__categories-body {
        display: none;

        list-style: none;
        flex-direction: column;

        z-index: 999;
        position: relative;
    }

    &__categories-block:hover &__categories-body {
        display: flex;
    }

    &__category {
        a:link,
        a:visited {
            display: block;
            padding: 1.2rem 1.6rem;
            text-decoration: none;
            background-color: #fff;
            color: $color-secondary;
        }

        a:hover,
        a:active {
            background-color: #fafafa;
        }
    }

    &__search-form {
        display: flex;
        gap: 2.4rem;

        background-color: #fff;
        height: 3.6rem;
        border-radius: 10rem;
        padding: 0.6rem;
        overflow: hidden;

        @media (max-width: 900px) {
            display: none;
        }
    }

    &__search-input {
        border: none;
        font-size: 1.2rem;
        font-family: inherit;
        padding-left: 1.2rem;
        min-width: 0;
        width: 20rem;
        outline: none;
    }

    &__search-cat-select {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        font-size: 1.2rem;
        cursor: pointer;
    }

    &__search-cat-icons {
        display: flex;
        flex-direction: column;

        & ion-icon {
            display: block;
        }
    }

    &__search-btn {
        width: 2.4rem;
        height: 2.4rem;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-tertiary;
    }

    &__search-icon {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        color: #fff;
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        color: #fff;
    }

    &__right-icon {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
    }

    &__right-text {
        // text-decoration: underline;
    }
}
