.newsletter {
    &__container {
        background-color: $color-tertiary-darker;
        border-radius: 1.6rem;
        padding: 5.2rem 7.2rem !important;
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4.8rem;

        @media (max-width: 750px) {
            padding: 5.2rem 2.4rem !important;
            justify-content: center;
            text-align: center;
        }
    }

    &__img {
        height: 20rem;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__heading {
        font-size: 5rem;
        font-weight: 600;
        line-height: 1.2;

        margin-bottom: 2.4rem;

        @media (max-width: 850px) {
            font-size: 4rem;
        }

        @media (max-width: 600px) {
            font-size: 3.2rem;
        }
    }

    &__sub-heading {
        font-size: 1.75rem;
        margin-bottom: 3.6rem;
    }

    &__search-form {
        height: 5.6rem;
        background-color: #fff;
        border-radius: 10rem;
        overflow: hidden;
        padding: 0.6rem;

        display: flex;
        gap: 1.2rem;

        @media (max-width: 600px) {
            flex-direction: column;
            height: auto;
            border-radius: 1.2rem;
            gap: 0.6rem;
        }
    }

    &__search-input {
        min-width: 0;
        flex: 1;
        background-color: transparent;
        font-family: inherit;
        padding-left: 2.4rem;
        border: none;
        outline: none;

        @media (max-width: 600px) {
            flex: 0 0 4.8rem;
            padding-left: 1.2rem;
        }
    }

    &__button {
        background-color: $color-tertiary;
        padding: 0 2.4rem;
        border: none;
        border-radius: 10rem;
        font-family: inherit;
        color: #fff;

        @media (max-width: 600px) {
            height: 4.8rem;
            border-radius: 1.2rem;
        }
    }
}
