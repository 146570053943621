@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cantata+One&display=swap");

body {
    color: #555;
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.heading-primary {
    font-size: 3.6rem;
    font-family: "Cantata One", serif;
    color: $color-secondary;
}
