.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    height: 5.2rem;
    background-color: $color-tertiary;
    color: #fff;
    border: none;
    font-family: inherit;
    font-size: 1.6rem;
    border-radius: 0.6rem;
    text-decoration: none;
    cursor: pointer;

    &--sm {
        height: 4.8rem;
    }

    & ion-icon {
        display: block;
    }
}

.button-secondary {
    padding: 1.2rem 1.8rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: inherit;
    border: 2px solid $color-secondary;
    color: $color-secondary;
    cursor: pointer;
    transition: all 0.3s;
    background-color: transparent;
    text-decoration: none;

    &--active,
    &:hover {
        background-color: $color-secondary;
        color: #fff;
    }
}

.button-primary {
    padding: 1.2rem 1.8rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: inherit;
    background-color: $color-tertiary;
    color: #fff;
    border: none;
    cursor: pointer;
    text-decoration: none;
}
