.pagination {
    display: flex;
    height: 4.8rem;
    width: 9.6rem;
    border-radius: 10rem;
    overflow: hidden;
    background-color: $color-tertiary;

    &__control {
        flex: 1;

        border: none;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2;

        &--hovered,
        &:hover {
            background-color: $color-tertiary-darker;
        }

        & ion-icon {
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            color: #fff;
        }
    }
}

.pagination2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;

    div {
        display: flex;
        gap: 1.2rem;
    }

    button {
        background-color: #eaeaea;
        border: none;
        width: 3.6rem;
        height: 3.6rem;
        cursor: pointer;
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        color: #888;
    }
}
