.books-block {
    padding: 6.2rem 0;

    &__heading {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 500;
        margin-bottom: 2.4rem;
    }

    &__filter {
        text-align: center;
        margin-bottom: 2.4rem;

        display: none;

        @media (max-width: 1050px) {
            display: block;
        }
    }

    &__filter-btn {
        display: inline-flex;
        align-items: center;
        gap: 1.2rem;

        font-family: inherit;
        color: #fff;
        background-color: $color-tertiary-darker;
        border: none;
        padding: 1.2rem 2.4rem;
        border-radius: 0.6rem;
        cursor: pointer;
        font-size: 1.6rem;

        & ion-icon {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            color: #fff;
        }
    }

    &__main {
        display: flex;
        gap: 1.8rem;

        @media (max-width: 1050px) {
            flex-direction: column;
        }
    }

    &__main-left {
        flex: 0 0 30rem;

        display: flex;
        flex-direction: column;
        gap: 1.8rem;

        @media (max-width: 1050px) {
            width: 35rem;
            max-width: 100%;
            margin: auto;
        }

        @media (max-width: 450px) {
            width: 100%;
        }
    }

    &__main-right {
        flex: 1;
    }

    &__input-groups {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
}

.books-block-card {
    padding: 1.6rem;
    border: 1px solid #eaeaea;
    margin-bottom: 2.4rem;
    border-radius: 0.6rem;

    &__heading {
        color: #000;
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
        font-weight: 500;
    }
}
