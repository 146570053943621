.auth {
    width: 40rem;
    max-width: 100%;
    margin: auto;

    padding: 5.6rem 0;

    &__logo-block {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 3.6rem;
    }

    &__logo {
        display: block;
        height: 3.8rem;
    }

    &__header {
        margin-bottom: 2.4rem;

        display: flex;
        flex-direction: column;
        gap: 6px;

        text-align: center;
    }

    &__heading {
        font-size: 2.8rem;
        font-weight: 700;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    &__form-footer {
        margin-top: 2.4rem;

        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &__footer {
        margin-top: 4.2rem;

        @media (max-width: 1150px) {
            text-align: center;
        }

        & a {
            &:link,
            &:visited {
                color: inherit;
                font-weight: 500;
                text-decoration: none;
            }
        }
    }
}
