// .horizontal-bar-wrap {
//     height: 4px;
//     width: 100%;
//     margin: auto;
// }

// .horizontal-bar-wrap .bar {
//     position: relative;
//     width: 0%;
//     height: 100%;
//     margin: 0 auto;
//     animation: barWidth;
//     animation-duration: 2s;
//     animation-iteration-count: infinite;
//     animation-timing-function: linear;
// }

// .horizontal-bar-wrap .bar.bar1 {
//     animation-delay: 0s;
//     background-image: linear-gradient(#ffa94d, #fd7e14);
//     top: 0;
//     z-index: 1;
// }

// .preloader {
//     position: fixed;
//     top: 0;
//     left: 0;

//     width: 100vw;
//     height: 100vh;

//     background-color: #fff4e6;
//     z-index: 9999;
//     opacity: 1;
//     visibility: visible;
//     transition: all 0.4s;

//     display: flex;
//     align-items: center;
//     justify-content: center;

//     &.hidden {
//         opacity: 0;
//         visibility: hidden;
//     }

//     &__main {
//         width: 50rem;
//         max-width: 90%;
//         display: flex;
//         flex-direction: column;
//         gap: 2px;
//     }

//     &__logo-text {
//         text-align: center;
//         font-size: 3.6rem;
//         text-transform: uppercase;
//         color: transparent;
//         font-weight: 500;

//         background-image: linear-gradient(#ffa94d, #fd7e14);

//         -webkit-background-clip: text;
//         background-clip: text;

//         & span {
//             font-size: 7.2rem;
//         }
//     }
// }

/**
 * ==============================================
 * Preloader
 * ==============================================
 */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 2.4rem;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;

        width: 35rem;
        max-width: 100%;
    }

    &__logo {
        display: block;
        height: 4rem;
    }
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-tertiary-darker;
    color: $color-tertiary-darker;
    box-shadow: 9999px 0 0 0 $color-tertiary-darker;
    animation: dot-falling 1s infinite linear;
    animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-falling::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-tertiary-darker;
    color: $color-tertiary-darker;
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
}
.dot-falling::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-tertiary-darker;
    color: $color-tertiary-darker;
    animation: dot-falling-after 1s infinite linear;
    animation-delay: 0.2s;
}

@keyframes dot-falling {
    0% {
        box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 9999px 0 0 0 $color-tertiary-darker;
    }
    100% {
        box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
}
@keyframes dot-falling-before {
    0% {
        box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 9984px 0 0 0 $color-tertiary-darker;
    }
    100% {
        box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
}
@keyframes dot-falling-after {
    0% {
        box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
        box-shadow: 10014px 0 0 0 $color-tertiary-darker;
    }
    100% {
        box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
}
