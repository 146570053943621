.pgn {
    position: fixed;
    right: 1.2rem;
    bottom: 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    width: 35rem;
    height: auto;

    &__notification {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        padding: 1.6rem;
        border-radius: 3px;
        box-shadow: 0 1.4rem 4rem rgba(#000, 0.15);
        background-color: #fff;
        position: relative;
        z-index: 999;

        animation: notificationSlideIn 0.5s ease-in-out 0.3s;
        animation-fill-mode: both;

        & ion-icon {
            width: 2.4rem;
            height: 2.4rem;
        }

        &--success {
            border-left: 6px solid #37b24d;

            & ion-icon {
                color: #37b24d;
            }
        }

        &--error {
            border-left: 6px solid #f03e3e;

            & ion-icon {
                color: #f03e3e;
            }
        }

        &--info {
            border-left: 6px solid #1c7ed6;

            & ion-icon {
                color: #1c7ed6;
            }
        }

        &--warning {
            border-left: 6px solid #fcc419;

            & ion-icon {
                color: #fcc419;
            }
        }
    }

    &__main {
        flex: 1;

        display: flex;
        flex-direction: column;
        gap: 0.6rem;
    }

    &__heading {
        font-size: 1.8rem;
        font-weight: 600;
    }

    &__text {
        font-size: 1.4rem;
    }

    &__close {
        position: absolute;
        top: 0.6rem;
        right: 0.6rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #e5e5e5;
        border-radius: 50%;
        cursor: pointer;

        height: 1.6rem;
        width: 1.6rem;

        & ion-icon {
            color: #4f4f4f;
            display: block;
            height: 1.2rem;
            width: 1.2rem;
        }
    }
}
