.placeholder {
    background: darkgray;
    background: linear-gradient(
        to right,
        #eeeeee 10%,
        #dddddd 18%,
        #eeeeee 33%
    );
    background-size: 800px 104px;

    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
}
