.cart-block {
    padding: 6.2rem 0;

    &__heading {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 500;
        margin-bottom: 2.4rem;
    }

    &__main {
        display: flex;
        gap: 1.8rem;
    }

    &__main-left {
        flex: 1;
    }

    &__main-right {
        flex: 0 0 30rem;

        display: flex;
        flex-direction: column;
        gap: 1.8rem;

        @media (max-width: 950px) {
            display: none;
        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        gap: 3.6rem;

        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 0.6rem;
        padding: 2rem;
    }
}

.checkout-block {
    &__heading {
        font-weight: 500;
        padding-bottom: 1.2rem;
        border-bottom: 1px solid #ddd;
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: 1.8rem;
        padding: 1.2rem 0;
        border-bottom: 1px solid #ddd;
    }
}

.cart-products {
    &__header {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;

        margin-bottom: 1.6rem;
    }

    &__heading {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        font-weight: 500;

        & ion-icon {
            display: block;
            color: $color-tertiary;
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    &__header-right {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        & span {
            color: $color-tertiary-darker;
            font-weight: 500;
        }
    }
}

.cart-product {
    display: flex;
    gap: 2.4rem;

    padding: 1.6rem;
    border-bottom: 1px solid #ddd;

    @media (max-width: 650px) {
        gap: 2.4rem;
    }

    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    &__img-block {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        & ion-icon {
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            color: $color-tertiary;
        }
    }

    &__img {
        width: 8rem;
    }

    &__right {
        margin-left: auto;

        @media (max-width: 600px) {
            margin-left: 0;
            grid-column: 1 / -1;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__author {
        font-size: 1.4rem;
        margin-bottom: 4px;
    }

    &__name {
        font-size: 2.2rem;
        color: $color-secondary;
        margin-bottom: 0.8rem;
        font-weight: 500;
        cursor: pointer;

        @media (max-width: 450px) {
            font-size: 1.8rem;
        }
    }

    &__price-block {
        display: flex;
        align-items: center;
        gap: 1.8rem;

        color: $color-tertiary-darker;
    }

    &__label {
        display: inline-block;
        padding: 0.8rem;
        font-size: 1rem;
        color: $color-tertiary;
        border: 1px solid $color-tertiary;
        border-radius: 0.6rem;

        @media (max-width: 600px) {
            border: none;
            padding: 0;
        }

        @media (max-width: 450px) {
            display: none;
        }
    }

    &__link {
        &:link,
        &:visited {
            color: $color-secondary;
            text-decoration: underline;
            font-size: 1.2rem;

            margin-top: auto;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        & button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 3.6rem;
            height: 3.6rem;

            cursor: pointer;
            border-radius: 0.6rem;
            border: none;
            background-color: #fff;

            & ion-icon {
                display: block;
                width: 1.8rem;
                height: 1.8rem;
                color: #555;
            }
        }
    }

    &__right-text {
        margin-top: auto;
        font-size: 1.2rem;
    }
}
