.footer {
}

.copyright {
    border-top: 1px solid #ddd;
    padding: 1.2rem 0;

    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.4rem;

        @media (max-width: 400px) {
            flex-direction: column;
        }
    }

    &__text {
        color: $color-secondary;
        font-size: 1.6rem;

        & a {
            &:link,
            &:visited {
                text-decoration: none;
                color: $color-tertiary-darker;
            }
        }

        @media (max-width: 400px) {
            text-align: center;
        }
    }

    &__img {
        height: 3.6rem;
    }
}
