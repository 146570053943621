.wishlist {
    padding: 6.2rem 0;

    &__heading {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 500;
        margin-bottom: 2.4rem;
    }

    &__main {
        max-width: 80rem;
        margin: auto;
    }
}
