*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;

    @media (max-width: 550px) {
        font-size: 55%;
    }

    @media (max-width: 360px) {
        font-size: 50%;
    }
}

body {
    box-sizing: border-box;
    background-color: #f7f7f7;
    overflow-x: hidden;
}

.main {
    min-height: calc(100vh - 244px);
}
