@keyframes pageLoaderAnim {
    0% {
        left: -110%;
    }
    100% {
        left: 110%;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes barWidth {
    0% {
        width: 0%;
    }
    25% {
        width: 50%;
    }
    50% {
        width: 100%;
    }
    75% {
        width: 50%;
    }
    100% {
        width: 0%;
    }
}

@keyframes barWidth2 {
    0% {
        width: 0%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

@keyframes notificationSlideIn {
    0% {
        transform: translateX(40rem);
    }

    100% {
        transform: translateX(0);
    }
}
