.book-details-block {
    padding: 6.2rem 0;
}

.book-details {
    display: flex;
    gap: 4.8rem;

    max-width: 85rem;
    margin: auto;

    margin-bottom: 4.8rem;

    @media (max-width: 850px) {
        gap: 2.4rem;
    }

    @media (max-width: 750px) {
        flex-direction: column;
    }

    &__img-block {
        flex: 0 0 30rem;

        @media (max-width: 750px) {
            display: block;
            text-align: center;
        }
    }

    &__img {
        display: block;
        width: 100%;

        @media (max-width: 750px) {
            display: inline-block;
            width: 30rem;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        @media (max-width: 750px) {
            text-align: center;
        }
    }

    &__name {
        font-size: 2.8rem;
        font-weight: 600;
        color: $color-secondary;
    }

    &__author {
    }

    &__review {
        display: flex;
        align-items: center;
        gap: 1.8rem;

        @media (max-width: 750px) {
            justify-content: center;
        }
    }

    &__stars {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        & ion-icon {
            display: block;
            width: 1.8rem;
            height: 1.8rem;

            &.active {
                color: $color-primary;
            }
        }
    }

    &__description {
        font-size: 1.8rem;
        color: $color-secondary;
        text-align: justify;

        @media (max-width: 750px) {
            text-align: center;
        }
    }

    &__price {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        @media (max-width: 750px) {
            justify-content: center;
        }
    }

    &__price-main {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        color: $color-tertiary-darker;
        font-size: 2rem;
    }

    &__price-del {
        font-size: 1.2rem;
        text-decoration: line-through;
    }

    &__wishlist-btn {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        font-size: 2.2rem;
        text-transform: uppercase;
        color: $color-secondary;
        background-color: transparent;
        cursor: pointer;
        border: none;
        font-family: inherit;
        font-weight: 500;

        & ion-icon {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
        }

        @media (max-width: 750px) {
            justify-content: center;
        }
    }

    &__main-footer {
        margin-top: auto;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        @media (max-width: 750px) {
            justify-content: center;
        }

        @media (max-width: 400px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            & > *:first-child {
                grid-column: 1 / -1;
            }
        }
    }
}

.book-tab {
    margin-bottom: 4.8rem;

    &__header {
        display: inline-flex;
        align-items: center;

        border: 1px solid #ddd;
        border-bottom: 0;

        & button {
            background-color: transparent;
            color: $color-secondary;
            font-size: 1.4rem;
            transition: all 0.2s;
            font-family: inherit;
            padding: 1.2rem;
            border: none;
            background-color: transparent;
            cursor: pointer;

            &:not(:last-child) {
                border-right: 1px solid #ddd;
            }

            &.active,
            &:hover {
                background-color: #ddd;
            }
        }
    }

    &__body {
        border: 1px solid #ddd;
        padding: 3.6rem 2.4rem;
    }

    &__block {
        &:not(:last-child) {
            margin-bottom: 3.6rem;
        }
    }

    &__heading {
        font-size: 2.2rem;
        color: $color-secondary;
        font-weight: 400;

        margin-bottom: 1.8rem;
    }

    &__text {
        font-size: 1.6rem;
    }

    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        & li {
            display: flex;
            align-items: center;
            gap: 1.6rem;

            font-size: 1.6rem;
        }
    }
}

.similar-books {
    display: flex;
    gap: 1.8rem;

    &__left {
        flex: 0 0 35rem;

        position: relative;

        border-radius: 1.2rem;
        overflow: hidden;

        padding: 2.4rem;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: -1;
        }

        @media (max-width: 800px) {
            display: none;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -2;

            object-fit: cover;
        }
    }

    &__left-heading {
        font-size: 4.8rem;
        color: $color-tertiary-darker;
        font-weight: 600;

        margin-bottom: 2rem;
    }

    &__left-contact {
        display: flex;
        flex-direction: column;
        gap: 3px;
        color: #fff;

        & p:first-child {
            font-size: 1.8rem;
        }

        & p:last-child {
            font-size: 2.6rem;
            font-weight: 500;
        }
    }

    &__right {
        flex: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        display: flex;
        gap: 1.8rem;

        & > * {
            flex: 0 0 25rem;
        }
    }
}
